<template>
  <div id="app">
    <ComingSoon3D />
    <SocialLinks 
      xingUrl="https://www.xing.com/profile/Konrad_Hausmann3/"
      email="hausmann.konrad@gmail.com"
    />
  </div>
</template>

<script>
import ComingSoon3D from './components/ComingSoon3D.vue';
import SocialLinks from './components/SocialLinks.vue';

export default {
  components: {
    ComingSoon3D,
    SocialLinks
  }
};
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ffffff; 
  color: rgb(0, 0, 0);
  text-align: center;
}
</style>
