<template>
    <div class="social-links-box">
      <a :href="xingUrl" target="_blank" rel="noopener noreferrer" aria-label="Xing">
        <img src="@/assets/icons/xing.svg" alt="Xing" class="social-icon" />
      </a>
      <a :href="`mailto:${email}`" aria-label="E-Mail">
        <img src="@/assets/icons/email.svg" alt="E-Mail" class="social-icon" />
      </a>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SocialLinks',
    props: {
      xingUrl: {
        type: String,
        required: true
      },
      email: {
        type: String,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  .social-links-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 5px 10px; /* Minimaler Padding, um die Icons genau zu umschließen */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    width: fit-content; /* Box passt sich der Größe des Inhalts an */
  }
  
  .social-icon {
    width: 32px;
    height: 32px;
    margin: 0 5px; /* Kleinerer Abstand zwischen den Icons */
    transition: transform 0.2s;
  }
  
  .social-icon:hover {
    transform: scale(1.1);
  }
  </style>
  