<template>
  <div ref="p5Canvas"></div>
</template>

<script>
import p5 from 'p5';
import Matemasie from '@/assets/fonts/Matemasie-Regular.ttf';  // Pfad zur lokalen Schriftart

export default {
  name: 'ComingSoon3D',
  mounted() {
    this.sketch = new p5(this.createSketch, this.$refs.p5Canvas);
  },
  beforeUnmount() {
    this.sketch.remove(); // Entfernt die p5-Instanz, wenn die Komponente zerstört wird
  },
  methods: {
    createSketch(p) {
      let font;

      p.preload = function () {
        font = p.loadFont(Matemasie);
      };

      p.setup = function () {
        p.createCanvas(600, 400, p.WEBGL);
        p.textFont(font);
      };

      p.draw = function () {
        p.background('white');
        p.fill(255);
        p.textAlign(p.CENTER, p.CENTER);
        p.textSize(60);  // Textgröße angepasst für bessere Lesbarkeit

        p.rotateY(p.frameCount * 0.02); // Rotationseffekt auf der Y-Achse
        
        p.push();
  for(let i=0; i<100; i++) {
    p.fill(p.map(i,0,100,0,255),80,215);
    p.translate(0,0,.5);
    p.text('Coming Soon', 0, 0);
  }
 p.pop(); 
      };
    }
  }
};
</script>

<style scoped>
/* Optional: Hier kannst du Styles für die Canvas-Umgebung hinzufügen */
</style>
